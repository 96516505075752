export class Prize {
    _id = '';
    name: string;
    vendor = new Vendor();
    initialQuantity: number;
    remainingQuantity: number;
    dailyQuota: number;
    promotion: string;
    type: string;
    simCardPrice: number;
    addOnCode: string;
    validity: number;
    giftLevel: number;
    dailyQuantity: number;
    prize: string;
    usefor: string;
    isPhysicalPrize = false;
    imageUrl: string;
    exchangePoint: number;
    description: string;
    availableShops: string[];
}

export class NewPrize {
    constructor(obj: Prize) {
        this.name = obj.name;
        this.vendor = obj.vendor._id;
        this.initialQuantity = obj.initialQuantity;
        this.remainingQuantity = obj.remainingQuantity;
        this.dailyQuota = obj.dailyQuota;
        this.promotion = obj.promotion;
        this.type = obj.type;
        this.simCardPrice = obj.simCardPrice;
        this.addOnCode = obj.addOnCode;
        this.validity = obj.validity;
        this.giftLevel = obj.giftLevel;
        this.dailyQuantity = obj.dailyQuantity;
        this.prize = obj.prize;
        this.usefor = obj.usefor;
        this.isPhysicalPrize = obj.isPhysicalPrize;
        this.exchangePoint = obj.exchangePoint;
        this.imageUrl = obj.imageUrl;
        this.description = obj.description;
        this.availableShops = obj.availableShops;
    }
    name = '';
    vendor = '';
    initialQuantity = 0;
    remainingQuantity = 0;
    dailyQuota = 1;
    promotion = '';
    type = '';
    simCardPrice = 0;
    addOnCode = '';
    validity = 1;
    giftLevel = 1;
    dailyQuantity = 1;
    prize = '';
    usefor = '';
    isPhysicalPrize = false;
    exchangePoint = 0;
    imageUrl = '';
    description = '';
    availableShops = [];
}

export class Shop {
    name: string;
    address: string;
}

export class Vendor {
    _id = '';
    code = '';
    name = '';
}

export class CashRushPrize {
    _id = '';
    name = '';
    promotion = '';
    giftLevel = 1;
    dailyQuantity = 0;
    prize = '';
}
export class CashRushPrizeNew {
    constructor(obj: CashRushPrize) {
        this.name = obj.name;
        this.promotion = obj.promotion;
        this.giftLevel = obj.giftLevel;
        this.dailyQuantity = obj.dailyQuantity;
        this.prize = obj.prize;
    }
    name = '';
    promotion = '';
    giftLevel = 1;
    dailyQuantity = 0;
    prize = '';
}
